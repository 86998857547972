import Calculator from './Components/Calculator';
import debounce from 'lodash.debounce';
import EmotionThemeProvider from './providers/EmotionThemeProvider';
import { EntryProvider } from './providers/EntryProvider';
import React from 'react';
import { StackProvider } from './providers/StackProvider';

//  Set vertical height to deal with mobile chrome and safari calculating with the address bar
const setVerticalHeight = () =>
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
setVerticalHeight();

//  And deal with various resizing events
const debouncedSetVerticalHeight = debounce(setVerticalHeight, 100, { maxWait: 1000 });
window.addEventListener('resize', debouncedSetVerticalHeight);
window.addEventListener('orientationchange', debouncedSetVerticalHeight);

function App() {
  return (
    <EmotionThemeProvider>
      <StackProvider>
        <EntryProvider>
          <Calculator />
        </EntryProvider>
      </StackProvider>
    </EmotionThemeProvider>
  );
}

export default App;
