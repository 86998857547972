import { EntryContext } from '../providers/EntryProvider';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.secondary['500']};
  padding: 0.2rem 0.5rem;
  text-align: right;
`;

export default () => {
  const entryContext = React.useContext(EntryContext);
  const { entry } = entryContext;

  return <Container>{entry}</Container>;
};
