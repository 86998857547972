import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import blueGrey from '@material-ui/core/colors/blueGrey';
import CssBaseline from '@material-ui/core/CssBaseline';
import grey from '@material-ui/core/colors/grey';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

const darkTheme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: grey,
    type: 'dark',
  },
});

ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

serviceWorker.register();
