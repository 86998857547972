import { ERRORS } from '../../constants';
import React from 'react';

const HISTORY_LIMIT = 100;

export default () => {
  const [currentStack, setCurrentStack] = React.useState([]);
  const [completeStack, setCompleteStack] = React.useState([]);

  const update = (newStackEntry) => {
    const fullStack = completeStack.concat([newStackEntry]);
    if (fullStack.length > HISTORY_LIMIT) {
      fullStack.shift();
    }

    setCurrentStack(newStackEntry);
    setCompleteStack(fullStack);

    window.localStorage.setItem('stack', JSON.stringify(fullStack));
  };

  const clear = () => {
    update([]);
  };

  const dupe = () => {
    const len = currentStack.length;
    if (len) {
      const newStackEntry = [...currentStack];
      update(newStackEntry.concat(newStackEntry[len - 1]));
    }
  };

  //  Doesn't remove the entries
  const fetch = (numEntries = 1) => {
    if (currentStack.length < numEntries) {
      throw ERRORS.NOT_ENOUGH_ENTRIES;
    }
    return currentStack.slice(currentStack.length - numEntries);
  };

  const push = (val) => {
    const lastEntry = completeStack.length ? currentStack : [];
    update(lastEntry.concat(val));
  };

  const pop = () => {
    if (currentStack.length) {
      const newStackEntry = [...currentStack];
      const entry = newStackEntry.pop();
      update(newStackEntry);
      return entry;
    }
  };

  const replace = (numEntries, newValue) => {
    if (currentStack.length < numEntries) {
      throw ERRORS.NOT_ENOUGH_ENTRIES;
    }
    const newStackEntry = [...currentStack].slice(0, currentStack.length - numEntries);
    update(newStackEntry.concat(newValue));
  };

  const rotDown = () => {
    if (currentStack.length > 1) {
      const newStackEntry = [...currentStack];
      const btm = newStackEntry.pop();
      newStackEntry.unshift(btm);
      update(newStackEntry);
    }
  };

  const rotUp = () => {
    if (currentStack.length > 1) {
      const newStackEntry = [...currentStack];
      const top = newStackEntry.shift();
      update(newStackEntry.concat(top));
    }
  };

  const swap = () => {
    if (currentStack.length > 1) {
      const newStackEntry = [...currentStack];
      const x = newStackEntry.pop();
      const y = newStackEntry.pop();
      update(newStackEntry.concat(x, y));
    }
  };

  const undo = () => {
    if (completeStack.length) {
      completeStack.pop();
      setCurrentStack(completeStack.length ? completeStack[completeStack.length - 1] : []);
    }
  };

  React.useEffect(() => {
    const history = window.localStorage.getItem('stack');
    if (history) {
      const parsedHistory = JSON.parse(history);
      if (history && history.length) {
        setCompleteStack(parsedHistory);
        setCurrentStack(parsedHistory[parsedHistory.length - 1]);
      }
    }
  }, []);

  return { clear, currentStack, dupe, fetch, pop, push, replace, rotDown, rotUp, swap, undo };
};
