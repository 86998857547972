import { BTNS } from '../constants';
import Button from './Button';
import React from 'react';

export default React.memo(() => {
  return (
    <React.Fragment>
      <Button col={1} def={BTNS.SWAP} row={8} />
      <Button col={1} def={BTNS.UNDO} row={9} />

      <Button col={2} def={BTNS.ROTATE_UP} row={8} />
      <Button col={2} def={BTNS.ROTATE_DOWN} row={9} />

      <Button col={3} def={BTNS.DELETE} row={8} />
      <Button col={3} def={BTNS.DELETE_ALL} row={9} />
    </React.Fragment>
  );
});
