import { DISP } from '../constants';
import React from 'react';
import { StackContext } from '../providers/StackProvider';
import styled from '@emotion/styled';
import useWindow from '../hooks/effects/useWindow';

const Container = styled.div`
  align-self: flex-end;
  padding: 0.5rem;
  text-align: right;
`;

export default React.memo(() => {
  const windowHeight = useWindow();
  const stackContext = React.useContext(StackContext);
  const { currentStack, dispMode, dispSize } = stackContext;

  const renderNumber = (val) => {
    if (!Number.isFinite(val)) {
      return val;
    }

    switch (dispMode) {
      case DISP.MODES.FIXED: {
        return val.toFixed(dispSize);
      }
      case DISP.MODES.PRECISION: {
        return val.toPrecision(dispSize);
      }
      case DISP.MODES.EXP: {
        return val.toExponential(dispSize);
      }
      default:
        return val;
    }
  };

  //  Hack to figure out how many rows we can actually show in the stack.
  const lineHeight = 1.43;
  const vh = windowHeight * 0.01;
  const rem = 16;
  const fontSize = Math.max(3 * vh, rem);
  const stackHeight = (vh * 100 * 7) / 9 - 2 * Math.max(6 * vh, 1.5 * rem) - rem;
  const numRows = Math.floor(stackHeight / (fontSize * lineHeight));
  const rowsToHide = Math.max(currentStack.length - numRows, 0);

  return (
    <Container>
      {currentStack.map((val, index) => {
        if (index >= rowsToHide) {
          return <div key={index}>{renderNumber(val)}</div>;
        }
        return null;
      })}
    </Container>
  );
});
