import React from 'react';
import SettingsCommands from './SettingsCommands';
import { StackContext } from '../providers/StackProvider';
import styled from '@emotion/styled';

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary['500']};
  display: grid;
  font-size: 1rem;
  grid-template-columns: 1fr 1fr;
  padding: 0.2rem 0.5rem;

  :hover {
    background: ${(props) => props.theme.palette.secondary['800']};
    cursor: pointer;
  }
`;

const Trig = styled.div`
  justify-self: flex-start;
`;

const Display = styled.div`
  justify-self: flex-end;
`;

export default React.memo(() => {
  const stackContext = React.useContext(StackContext);
  const { dispMode, dispSize, trigMode } = stackContext;
  const [showMenu, setShowMenu] = React.useState(false);
  const [commandsEvent, setCommandsEvent] = React.useState(null);

  const onCloseMenu = () => {
    setShowMenu(false);
    setCommandsEvent(null);
  };
  const onShowMenu = (event) => {
    setShowMenu(true);
    const { clientX, clientY, currentTarget } = event;
    setCommandsEvent({ clientX, clientY, currentTarget });
  };

  return (
    <React.Fragment>
      <Container onClick={onShowMenu}>
        <Trig>{trigMode}</Trig>
        <Display>
          {dispMode.toLowerCase()}-{dispSize}
        </Display>
      </Container>
      {showMenu && <SettingsCommands triggerEvent={commandsEvent} onClose={onCloseMenu} />}
    </React.Fragment>
  );
});
