import { BTNS } from '../constants';
import Button from './Button';
import React from 'react';

export default React.memo(() => {
  return (
    <React.Fragment>
      <Button col={4} def={BTNS[7]} row={5} variant='light' />
      <Button col={5} def={BTNS[8]} row={5} variant='light' />
      <Button col={6} def={BTNS[9]} row={5} variant='light' />

      <Button col={4} def={BTNS[4]} row={6} variant='light' />
      <Button col={5} def={BTNS[5]} row={6} variant='light' />
      <Button col={6} def={BTNS[6]} row={6} variant='light' />

      <Button col={4} def={BTNS[1]} row={7} variant='light' />
      <Button col={5} def={BTNS[2]} row={7} variant='light' />
      <Button col={6} def={BTNS[3]} row={7} variant='light' />

      <Button col={4} def={BTNS.NEG} row={8} variant='light' />
      <Button col={5} def={BTNS[0]} row={8} variant='light' />
      <Button col={6} def={BTNS['.']} row={8} variant='light' />

      <Button col={4} def={BTNS.EXP} row={9} variant='light' />
      <Button col='5 / 7' def={BTNS.ENTER} row={9} variant='light' />
    </React.Fragment>
  );
});
