import React from 'react';
import StackContents from './StackContents';
import StackEntry from './StackEntry';
import StackSettings from './StackSettings';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  font-family: 'PT Mono', monospace;
  font-size: max(calc(var(--vh, 1vh) * 3), 1rem);
  font-weight: 600;
  grid-template-rows: max(calc(var(--vh, 1vh) * 6), 1.5rem) auto max(
      calc(var(--vh, 1vh) * 6),
      1.5rem
    );
  grid-column: 1 / 4;
  grid-row: 1 / 8;
  height: 100%;
`;

export default () => {
  return (
    <Container>
      <StackSettings />
      <StackContents />
      <StackEntry />
    </Container>
  );
};
