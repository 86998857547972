import debounce from 'lodash.debounce';
import React from 'react';

//  Need this so that when resize or orientation changes occur, we recompute
//  the size of the stack that is shown.
export default () => {
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  const onWindowChange = debounce(() => setWindowHeight(window.innerHeight), 100, {
    maxWait: 1000,
  });

  React.useEffect(() => {
    window.addEventListener('resize', onWindowChange);
    window.addEventListener('orientationchange', onWindowChange);

    return () => {
      window.removeEventListener('resize', onWindowChange);
      window.removeEventListener('orientationchange', onWindowChange);
    };
  }, [onWindowChange, windowHeight]);

  return windowHeight;
};
