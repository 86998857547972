import * as PropTypes from 'prop-types';
import { DISP, TRIG } from '../constants';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from '@material-ui/core/Select';
import { StackContext } from '../providers/StackProvider';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const StyledInputLabel = withStyles(() => ({
  focused: {
    color: 'inherit !important',
  },
}))(InputLabel);

const StyledFormControl = styled(FormControl)`
  margin-top: 1.5rem;
`;

const Text = styled(Typography)`
  margin-bottom: 1rem;
`;

const SettingsCommands = ({ onClose, triggerEvent }) => {
  const [showAbout, setShowAbout] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const stackContext = React.useContext(StackContext);
  const { dispMode, dispSize, setDispMode, setDispSize, setTrigMode, trigMode } = stackContext;

  const [formTrig, setFormTrig] = React.useState(trigMode);
  const [formDispMode, setFormDispMode] = React.useState(dispMode);
  const [formDispSize, setFormDispSize] = React.useState(dispSize);

  const onShowAbout = () => setShowAbout(true);
  const onShowSettings = () => setShowSettings(true);

  const onCloseAll = () => {
    setShowAbout(false);
    setShowSettings(false);
    onClose();
  };

  const onSave = () => {
    setTrigMode(formTrig);
    setDispMode(formDispMode);
    setDispSize(formDispSize);
    onCloseAll();
  };

  const renderSettings = () => (
    <Dialog disableBackdropClick={true} fullWidth={true} open={showSettings} onClose={onCloseAll}>
      <DialogTitle>Edit Settings</DialogTitle>
      <DialogContent>
        <StyledFormControl fullWidth={true}>
          <StyledInputLabel color='secondary' id='trig'>
            Trigonometry Mode
          </StyledInputLabel>
          <Select
            labelId='trig'
            onChange={(event) => setFormTrig(event.target.value)}
            value={formTrig}
          >
            <MenuItem key={0} value={TRIG.DEGS}>
              Degrees
            </MenuItem>
            <MenuItem key={1} value={TRIG.RADS}>
              Radians
            </MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl fullWidth={true}>
          <StyledInputLabel color='secondary' id='dispMode'>
            Display Mode
          </StyledInputLabel>
          <Select
            labelId='dispMode'
            onChange={(event) => setFormDispMode(event.target.value)}
            value={formDispMode}
          >
            <MenuItem key={0} value={DISP.MODES.FIXED}>
              Fixed
            </MenuItem>
            <MenuItem key={1} value={DISP.MODES.PRECISION}>
              Precision
            </MenuItem>
            <MenuItem key={2} value={DISP.MODES.EXP}>
              Exponential
            </MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl fullWidth={true}>
          <StyledInputLabel color='secondary' id='dispSize'>
            Number of Digits to Display
          </StyledInputLabel>
          <Select
            labelId='dispSize'
            onChange={(event) => setFormDispSize(event.target.value)}
            value={formDispSize}
          >
            {DISP.SIZES.map((s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseAll}>Cancel</Button>
        <Button color='primary' onClick={onSave} variant='contained'>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderAbout = () => (
    <Dialog disableBackdropClick={true} fullWidth={true} open={showAbout} onClose={onCloseAll}>
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <Text>
          A simple RPN calcator I created since I couldn't find a good app or website for this
          aleady.
        </Text>
        <Text>
          This is really intended for usage on a phone as a progressive web app (PWA) and is
          intended for use in landscape orientation only.
        </Text>
        <Text>Questions or comments? HMU at bigStack [at] yabers.com</Text>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onCloseAll} variant='contained'>
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (!triggerEvent || !triggerEvent.currentTarget) {
    return null;
  }

  return (
    <React.Fragment>
      {showAbout && renderAbout()}
      {showSettings && renderSettings()}
      <Menu
        anchorEl={triggerEvent.currentTarget}
        anchorPosition={{ left: triggerEvent.clientX, top: triggerEvent.clientY }}
        anchorReference='anchorPosition'
        onClose={onCloseAll}
        open={true}
      >
        <MenuItem key={0} onClick={onShowSettings}>
          Edit Settings...
        </MenuItem>
        <MenuItem key={1} onClick={onShowAbout}>
          About
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

SettingsCommands.propTypes = {
  onClose: PropTypes.func.isRequired,
  triggerEvent: PropTypes.shape({
    currentTarget: PropTypes.object.isRequired,
    clientX: PropTypes.number.isRequired,
    clientY: PropTypes.number.isRequired,
  }).isRequired,
};

export default SettingsCommands;
