import { BTNS, TRIG } from '../constants';
import React from 'react';
import { StackContext } from './StackProvider';

export const EntryContext = React.createContext({});

export const EntryProvider = ({ children }) => {
  const [entry, setEntry] = React.useState('');
  const stackContext = React.useContext(StackContext);
  const {
    clear,
    dupe,
    fetch,
    pop,
    push,
    replace,
    rotDown,
    rotUp,
    swap,
    trigMode,
    undo,
  } = stackContext;

  const standardKeys = [
    BTNS[0].VAL,
    BTNS[1].VAL,
    BTNS[2].VAL,
    BTNS[3].VAL,
    BTNS[4].VAL,
    BTNS[5].VAL,
    BTNS[6].VAL,
    BTNS[7].VAL,
    BTNS[8].VAL,
    BTNS[9].VAL,
  ];

  const addConstant = (value) => {
    if (entry !== '') {
      replace(0, [parseFloat(entry), value]);
      setEntry('');
    } else {
      push(value);
    }
  };

  const performOperation = (numEntries, op) => {
    try {
      let entries = [];
      if (entry !== '') {
        numEntries--;
        if (numEntries === 1) {
          entries = fetch(numEntries);
        }
        entries.push(parseFloat(entry));
        setEntry('');
      } else {
        entries = fetch(numEntries);
      }
      const result = op(...entries);
      replace(numEntries, result);
    } catch (e) {
      console.log(e);
    }
  };

  const performTrigOperation = (op) => {
    performOperation(1, (x) => op(trigMode === TRIG.RADS ? x : (x / 180.0) * Math.PI));
  };

  const performInverseTrigOperation = (op) => {
    performOperation(1, (x) => op(x) * (trigMode === TRIG.RADS ? 1.0 : 180.0 / Math.PI));
  };

  const getButton = (target) => {
    let el = target;
    while (el.parentNode) {
      if (el.tagName === 'BUTTON') {
        return el;
      }
      el = el.parentNode;
    }
  };

  const handleKeyPress = (event) => {
    //  For reasons I can't determine, sometimes the target is the content in
    //  the button, and not the button itself. I need the button.
    const button = getButton(event.target);
    if (!button) {
      return;
    }
    const key = button.value;

    if (standardKeys.includes(key)) {
      if (entry !== '0') {
        setEntry(entry + key);
      }
    } else {
      switch (key) {
        case BTNS['.'].VAL: {
          if (!entry.includes(key) && !entry.includes(BTNS.EXP.VAL)) {
            setEntry(entry + key);
          }
          break;
        }

        case BTNS['10_TO_X'].VAL: {
          performOperation(1, (x) => Math.pow(10.0, x));
          break;
        }

        case BTNS.ADD.VAL: {
          performOperation(2, (y, x) => x + y);
          break;
        }

        case BTNS.ARC_COS.VAL: {
          performInverseTrigOperation((x) => Math.acos(x));
          break;
        }

        case BTNS.ARC_COSH.VAL: {
          performInverseTrigOperation((x) => Math.acosh(x));
          break;
        }

        case BTNS.ARC_SIN.VAL: {
          performInverseTrigOperation((x) => Math.asin(x));
          break;
        }

        case BTNS.ARC_SINH.VAL: {
          performInverseTrigOperation((x) => Math.asinh(x));
          break;
        }

        case BTNS.ARC_TAN.VAL: {
          performInverseTrigOperation((x) => Math.atan(x));
          break;
        }

        case BTNS.ARC_TANH.VAL: {
          performInverseTrigOperation((x) => Math.atanh(x));
          break;
        }

        case BTNS.COS.VAL: {
          performTrigOperation((x) => Math.cos(x));
          break;
        }

        case BTNS.COSH.VAL: {
          performTrigOperation((x) => Math.cosh(x));
          break;
        }

        case BTNS.CUBED.VAL: {
          performOperation(1, (x) => Math.pow(x, 3.0));
          break;
        }

        case BTNS.CUBE_ROOT.VAL: {
          performOperation(1, (x) => Math.pow(x, 1.0 / 3.0));
          break;
        }

        case BTNS.DELETE.VAL: {
          if (entry !== '') {
            let newEntry = entry.slice(0, entry.length - 1);
            //  Don't leave a dangling minus sign
            if (newEntry[newEntry.length - 1] === '-') {
              newEntry = newEntry.slice(0, newEntry.length - 1);
            }
            setEntry(newEntry);
          } else {
            pop();
          }
          break;
        }

        case BTNS.DELETE_ALL.VAL: {
          if (entry !== '') {
            setEntry('');
          } else {
            clear();
          }
          break;
        }

        case BTNS.DELTA_PERCENT.VAL: {
          performOperation(2, (y, x) => ((x - y) / y) * 100.0);
          break;
        }

        case BTNS.DIVIDE.VAL: {
          performOperation(2, (y, x) => y / x);
          break;
        }

        case BTNS.E.VAL: {
          addConstant(Math.E);
          break;
        }

        case BTNS.E_TO_X.VAL: {
          performOperation(1, (x) => Math.pow(Math.E, x));
          break;
        }

        case BTNS.EXP.VAL: {
          if (entry !== '' && !entry.includes(BTNS.EXP.VAL)) {
            setEntry(entry + key);
          }
          break;
        }

        case BTNS.ENTER.VAL: {
          if (entry !== '') {
            push(parseFloat(entry));
            setEntry('');
          } else {
            dupe();
          }
          break;
        }

        case BTNS.INV.VAL: {
          performOperation(1, (x) => 1.0 / x);
          break;
        }

        case BTNS.LN.VAL: {
          performOperation(1, (x) => Math.log(x));
          break;
        }

        case BTNS.LOG.VAL: {
          performOperation(1, (x) => Math.log10(x));
          break;
        }

        case BTNS.MULTIPLY.VAL: {
          performOperation(2, (y, x) => y * x);
          break;
        }

        case BTNS.NEG.VAL: {
          if (entry !== '' && entry !== '0') {
            const expLocation = entry.indexOf(BTNS.EXP.VAL);
            if (expLocation > -1) {
              const exp = entry.slice(expLocation + 1);
              if (exp !== '') {
                if (exp.indexOf(BTNS.NEG.VAL) === -1) {
                  setEntry(entry.slice(0, expLocation + 1) + BTNS.NEG.VAL + exp);
                } else {
                  setEntry(entry.slice(0, expLocation + 1) + exp.slice(1));
                }
              }
            } else {
              if (entry.indexOf(BTNS.NEG.VAL) === -1) {
                setEntry(BTNS.NEG.VAL + entry);
              } else {
                setEntry(entry.slice(1));
              }
            }
          }
          break;
        }

        case BTNS.PI.VAL: {
          addConstant(Math.PI);
          break;
        }

        case BTNS.ROTATE_DOWN.VAL: {
          rotDown();
          break;
        }

        case BTNS.ROTATE_UP.VAL: {
          rotUp();
          break;
        }

        case BTNS.SIN.VAL: {
          performTrigOperation((x) => Math.sin(x));
          break;
        }

        case BTNS.SINH.VAL: {
          performTrigOperation((x) => Math.sinh(x));
          break;
        }

        case BTNS.SQUARED.VAL: {
          performOperation(1, (x) => Math.pow(x, 2.0));
          break;
        }

        case BTNS.SQUARE_ROOT.VAL: {
          performOperation(1, (x) => Math.pow(x, 1.0 / 2.0));
          break;
        }

        case BTNS.SWAP.VAL: {
          if (entry !== '') {
            const x = parseFloat(entry);
            const y = fetch(1);
            replace(1, [x, y]);
            setEntry('');
          } else {
            swap();
          }
          break;
        }

        case BTNS.SUBTRACT.VAL: {
          performOperation(2, (y, x) => y - x);
          break;
        }

        case BTNS.TAN.VAL: {
          performTrigOperation((x) => Math.tan(x));
          break;
        }

        case BTNS.TANH.VAL: {
          performTrigOperation((x) => Math.tanh(x));
          break;
        }

        case BTNS.UNDO.VAL: {
          undo();
          break;
        }

        case BTNS.Y_TO_X.VAL: {
          performOperation(2, (y, x) => Math.pow(y, x));
          break;
        }

        case BTNS.Y_TO_X_ROOT.VAL: {
          performOperation(2, (y, x) => Math.pow(y, 1.0 / x));
          break;
        }

        default: {
          break;
        }
      }
    }
  };

  const memoizedCallback = React.useCallback(handleKeyPress, null);

  React.useEffect(() => {
    const createKeyPress = (key) =>
      memoizedCallback({
        target: {
          parentNode: true,
          tagName: 'BUTTON',
          value: key,
        },
      });

    const passThroughKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'e', '.'];

    const onKeyDown = (event) => {
      const { key } = event;
      if (passThroughKeys.includes(key)) {
        createKeyPress(key);
      } else {
        switch (key) {
          case '+':
            createKeyPress(BTNS.ADD.VAL);
            break;
          case '-':
            if (entry === '') createKeyPress(BTNS.SUBTRACT.VAL);
            else {
              createKeyPress(BTNS.NEG.VAL);
            }
            break;
          case '*':
            createKeyPress(BTNS.MULTIPLY.VAL);
            break;
          case '/':
            createKeyPress(BTNS.DIVIDE.VAL);
            break;
          case 'ArrowRight':
          case 'ArrowLeft':
            createKeyPress(BTNS.SWAP.VAL);
            break;
          case 'ArrowUp':
            createKeyPress(BTNS.ROTATE_UP.VAL);
            break;
          case 'ArrowDown':
            createKeyPress(BTNS.ROTATE_DOWN.VAL);
            break;
          case 'Backspace':
            createKeyPress(BTNS.DELETE.VAL);
            break;
          case 'Enter':
            createKeyPress(BTNS.ENTER.VAL);
            break;
          default:
            return;
        }
      }

      event.stopPropagation();
      event.preventDefault();
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [entry, memoizedCallback]);

  return (
    <EntryContext.Provider value={{ entry, handleKeyPress }}>{children}</EntryContext.Provider>
  );
};
