import React from 'react';
import { TRIG, DISP } from '../constants';
import useStack from '../hooks/effects/useStack';

export const StackContext = React.createContext({});

export const StackProvider = ({ children }) => {
  const [dispMode, setDispMode] = React.useState(DISP.MODES.PRECISION);
  const [dispSize, setDispSize] = React.useState(DISP.SIZES[5]);
  const [trigMode, setTrigMode] = React.useState(TRIG.DEGS);

  return (
    <StackContext.Provider
      value={{ dispMode, dispSize, setDispMode, setDispSize, setTrigMode, trigMode, ...useStack() }}
    >
      {children}
    </StackContext.Provider>
  );
};
