import { BTNS } from '../constants';
import Button from './Button';
import React from 'react';

export default React.memo(() => {
  return (
    <React.Fragment>
      <Button col={4} def={BTNS.ARC_SINH} row={1} />
      <Button col={5} def={BTNS.ARC_COSH} row={1} />
      <Button col={6} def={BTNS.ARC_TANH} row={1} />
      <Button col={7} def={BTNS.PI} row={1} />
      <Button col={8} def={BTNS.E} row={1} />

      <Button col={4} def={BTNS.SINH} row={2} />
      <Button col={5} def={BTNS.COSH} row={2} />
      <Button col={6} def={BTNS.TANH} row={2} />
      <Button col={7} def={BTNS.DELTA_PERCENT} row={2} />
      <Button col={8} def={BTNS.INV} row={2} />

      <Button col={4} def={BTNS.ARC_SIN} row={3} />
      <Button col={5} def={BTNS.ARC_COS} row={3} />
      <Button col={6} def={BTNS.ARC_TAN} row={3} />
      <Button col={7} def={BTNS['10_TO_X']} row={3} />
      <Button col={8} def={BTNS.LOG} row={3} />

      <Button col={4} def={BTNS.SIN} row={4} />
      <Button col={5} def={BTNS.COS} row={4} />
      <Button col={6} def={BTNS.TAN} row={4} />
      <Button col={7} def={BTNS.E_TO_X} row={4} />
      <Button col={8} def={BTNS.LN} row={4} />

      <Button col={7} def={BTNS.Y_TO_X} row={5} />
      <Button col={8} def={BTNS.Y_TO_X_ROOT} row={5} />

      <Button col={7} def={BTNS.CUBED} row={6} />
      <Button col={8} def={BTNS.CUBE_ROOT} row={6} />

      <Button col={7} def={BTNS.SQUARED} row={7} />
      <Button col={8} def={BTNS.SQUARE_ROOT} row={7} />

      <Button col={7} def={BTNS.MULTIPLY} row={8} />
      <Button col={8} def={BTNS.DIVIDE} row={8} />

      <Button col={7} def={BTNS.ADD} row={9} />
      <Button col={8} def={BTNS.SUBTRACT} row={9} />
    </React.Fragment>
  );
});
