import { EntryContext } from '../providers/EntryProvider';
import MathCommands from './MathCommands';
import NumberPad from './NumberPad';
import React from 'react';
import Stack from './StackContainer';
import StackCommands from './StackCommands';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0.5rem;
  width: 100vw;
`;

export default () => {
  const entryContext = React.useContext(EntryContext);
  const { handleKeyPress } = entryContext;

  return (
    <Container onClick={handleKeyPress}>
      <Stack />
      <StackCommands />
      <MathCommands />
      <NumberPad />
    </Container>
  );
};
