import React from 'react';

const superscript = (label, power = -1, putFirst = false) => (
  <span>
    {putFirst && <sup>{power}</sup>}
    {label}
    {!putFirst && <sup>{power}</sup>}
  </span>
);

export const BTNS = {
  0: { LABEL: '0', VAL: '0' },
  1: { LABEL: '1', VAL: '1' },
  2: { LABEL: '2', VAL: '2' },
  3: { LABEL: '3', VAL: '3' },
  4: { LABEL: '4', VAL: '4' },
  5: { LABEL: '5', VAL: '5' },
  6: { LABEL: '6', VAL: '6' },
  7: { LABEL: '7', VAL: '7' },
  8: { LABEL: '8', VAL: '8' },
  9: { LABEL: '9', VAL: '9' },

  '.': { LABEL: '.', VAL: '.' },
  '10_TO_X': { LABEL: superscript('10', 'x'), VAL: '10_to_x' },
  ADD: { LABEL: '+', VAL: 'add' },
  ARC_COS: { LABEL: superscript('cos'), VAL: 'arc_cos' },
  ARC_SIN: { LABEL: superscript('sin'), VAL: 'arc_sin' },
  ARC_TAN: { LABEL: superscript('tan'), VAL: 'arc_tan' },
  ARC_COSH: { LABEL: superscript('cosh'), VAL: 'arc_cosh' },
  ARC_SINH: { LABEL: superscript('sinh'), VAL: 'arc_sinh' },
  ARC_TANH: { LABEL: superscript('tanh'), VAL: 'arc_tanh' },
  COS: { LABEL: 'cos', VAL: 'cos' },
  COSH: { LABEL: 'cosh', VAL: 'cosh' },
  CUBED: { LABEL: superscript('x', '3'), VAL: 'cubed' },
  CUBE_ROOT: { LABEL: superscript('√x', '3', true), VAL: 'cube_root' },
  DELETE: { LABEL: 'Del', VAL: 'del' },
  DELETE_ALL: { LABEL: 'Del All', VAL: 'del_all' },
  DELTA_PERCENT: { LABEL: 'Δ%', VAL: 'delta_percent' },
  DIVIDE: { LABEL: '÷', VAL: 'div' },
  E: { LABEL: 'e', VAL: 'e_val' },
  E_TO_X: { LABEL: superscript('e', 'x'), VAL: 'e_to_x' },
  EXP: { LABEL: 'EE', VAL: 'e' },
  ENTER: { LABEL: 'Enter', VAL: 'enter' },
  INV: { LABEL: '1 / x', VAL: 'inverse' },
  LN: { LABEL: 'ln', VAL: 'ln' },
  LOG: { LABEL: 'log', VAL: 'log' },
  MULTIPLY: { LABEL: '*', VAL: 'mult' },
  NEG: { LABEL: '+ / -', VAL: '-' },
  PI: { LABEL: 'π', VAL: 'pi' },
  ROTATE_DOWN: { LABEL: 'Rot ↓', VAL: 'rot_down' },
  ROTATE_UP: { LABEL: 'Rot ↑', VAL: 'rot_up' },
  SIN: { LABEL: 'sin', VAL: 'sin' },
  SINH: { LABEL: 'sinh', VAL: 'sinh' },
  SQUARED: { LABEL: superscript('x', '2'), VAL: 'squared' },
  SQUARE_ROOT: { LABEL: '√x', VAL: 'square_root' },
  SUBTRACT: { LABEL: '-', VAL: 'sub' },
  SWAP: { LABEL: 'x ↔ y', VAL: 'swap' },
  TAN: { LABEL: 'tan', VAL: 'tan' },
  TANH: { LABEL: 'tanh', VAL: 'tanh' },
  UNDO: { LABEL: 'Undo', VAL: 'undo' },
  Y_TO_X: { LABEL: superscript('y', 'x'), VAL: 'y_to_x' },
  Y_TO_X_ROOT: { LABEL: superscript('√y', 'x', true), VAL: 'y_to_x_root' },
};

export const DISP = {
  MODES: {
    EXP: 'Exp',
    FIXED: 'Fixed',
    PRECISION: 'Prec',
  },
  SIZES: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};

export const ERRORS = {
  NOT_ENOUGH_ENTRIES: 'not enough entries on stack',
};

export const TRIG = {
  RADS: 'rads',
  DEGS: 'degs',
};
